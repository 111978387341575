export const PERFIL = {
    ADMINISTRADOR: 'administrador',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
    ADMINISTRADOR_CONECTA: 'adminConecta',
};

const situacao = {
    CANCELADO: -10,
    CONVITES_ENVIADOS: 0,
    REAGENDADO: 10,
    CONFIRMADO: 20,
    EM_ANDAMENTO: 30,
    FINALIZADO: 40
}

export const SITUACAO_AGENDAMENTO = {
    SITUACAO: { ...situacao },
    LABEL: {
        [situacao.CANCELADO]: 'Cancelado',
        [situacao.CONVITES_ENVIADOS]: 'Convites enviados',
        [situacao.REAGENDADO]: 'Reeagendado',
        [situacao.CONFIRMADO]: 'Confirmado',
        [situacao.EM_ANDAMENTO]: 'Em andamento',
        [situacao.FINALIZADO]: 'Finalizado',
    },
    GRID: {
        [situacao.CANCELADO]: {
            descricao: 'Cancelado',
            cor: '#f4cbcb'
        },
        [situacao.CONVITES_ENVIADOS]: {
            descricao: 'Convites enviados',
            cor: '#faf7a9'
        },
        [situacao.REAGENDADO]: {
            descricao: 'Reeagendado',
            cor: '#fbd7b1'
        },
        [situacao.CONFIRMADO]: {
            descricao: 'Confirmado',
            cor: '#FFFFFF'
        },
        [situacao.EM_ANDAMENTO]: {
            descricao: 'Em andamento',
            cor: '#F3A46B'
        },
        [situacao.FINALIZADO]: {
            descricao: 'Finalizado',
            cor: '#b0d8b1'
        },
    },
}

export const PERMISSOES = {
    ADMINISTRATIVO: 'CONECTA_ADMINISTRATIVO',
    CONECTA: 'CONECTA_CONECTA',
}

export const MYME_TYPE_ARQUIVOS = {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    PDF: 'application/pdf',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const MYME_TYPE_PERMITIDOS = Object.values(MYME_TYPE_ARQUIVOS);

export const VINCULO_PERFIL_ESTADUAL = 'E';

export const TIPO_FUNCIONAMENTO_REDE = {
    CONVITE: 'C',
    AGENDA: 'A'
}

export const EVENTOS_SOCKET = {
    ALTERAR_STATUS_ATENDIMENTO_VIDEO: 'alterar_status_atendimento_video',
    // ATENDENTE_CONECTADO: 'atendente_conectado',
    // ATENDENTE_DESCONECTADO: 'atendente_desconectado',
    // ATENDENTE_ONLINE: 'atendente_online',
    // ATENDIDO_DESCONECTADO: 'atendido_desconectado',
    ATENDIMENTO_ENCERRADO: 'atendimento_encerrado',
    AVISO_DIGITANDO: 'aviso_digitando',
    CONNECTION: 'connection',
    DADOS_USUARIO: 'dados_usuario',
    DADOS_SALA: 'dados_sala',
    DISCONNECT: 'disconnect',
    // ENCAMINHAR_ATENDIMENTO: 'encaminhar_atendimento',
    ENVIAR_ANEXO: 'enviar_anexo',
    ENVIAR_AVISO_DIGITANDO: 'enviar_aviso_digitando',
    ENVIAR_MENSAGEM: 'enviar_mensagem',
    FINALIZAR_ATENDIMENTO: 'finalizar_atendimento',
    ID_SALA_ATENDIMENTO: 'id_sala_atendimento',
    // INICIAR_ATENDIMENTO: 'iniciar_atendimento',
    // LISTA_ATENDENTES: 'lista_atendentes',
    // LISTA_PARA_ATENDIMENTO: 'lista_para_atendimento',
    MENSAGEM_ENVIADA: 'mensagem_enviada',
    MENSAGENS_SALA: 'mensagens_sala',
    // NOTIFICACAO_SOLICITACAO_ATENDIMENTO: 'notificacao_solicitacao_atendimento',
    // SAIR_ATENDIMENTO: 'sair_atendimento',
    // SOLICITAR_ATENDIMENTO: 'solicitar_atendimento',
    // SOLICITAR_NOVO_ATENDIMENTO: 'solicitar_novo_atendimento',
    STATUS_ATENDIMENTO_VIDEO: 'status_atendimento_video',
    STATUS_ATENDIMENTO_VIDEO_INICIAL: 'status_atendimento_video_inicial',
    USUARIO_DESCONETADO: 'usuario_desconectado',
    USUARIO_ONLINE: 'usuario_online'
}