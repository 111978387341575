import React, { useContext, useRef } from "react";
import {
    SttBox,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import socket from "../../signals/socket";
import { EVENTOS_SOCKET, } from "../../common/Constants";
import { makeStyles } from "@material-ui/core";
import moment from 'moment';
import { batch } from "@preact/signals-react";
import { atendimentoEncerrado, emChamadaVideo, idEvento, idSalaAtendimento, quantidadeMembrosSala } from "../../signals/eventos";
import ExibicaoMensagemContainer from "./conteudo-mensagem";
// import ExibicaoMensagemContainer from "./exibicaoMensagemContainer";
// import { mudarNomeTab, pedirPermissaoNotificacao, resetTab } from "../common/Functions";

const useStyles = makeStyles((theme) => ({
    notificacao: {
        padding: theme.spacing(5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    divNotification: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        alignItems: 'center'
    }
}));

const MensagensContainer = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const mensagens = useSignal([]);
    const divRef = useRef();

    // const exibirNotificacao = (mensagem) => {
    //     if (document.hasFocus()) return;

    //     const options = {
    //         body: mensagem.anexo ? strings.notificacaoNovaMensagemAnexo : mensagem.mensagem,
    //         icon: global.gConfig.url_icone_notificacao,
    //         vibrate: [200, 100, 200],
    //         tag: 'notificacao-evento',
    //         renotify: true
    //     };

    //     const notification = new Notification(mensagem.responsavel.nome, options);
    //     mudarNomeTab(strings.notificacaoTabNovaMensagem);

    //     notification.onclick = () => {
    //         window?.focus();
    //         resetTab();
    //     };

    //     window.addEventListener('focus', resetTab);
    // }

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.MENSAGEM_ENVIADA, (dados) => {
                // Recebimento de uma nova mensagem
                const mensagemRecebida = JSON.parse(dados);
                mensagemRecebida.dataHora = new Date(mensagemRecebida.dataHora);

                mensagens.value = [...mensagens.value, mensagemRecebida];
                setTimeout(() => {
                    divRef.current && divRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start'
                    })

                }, 100);

                // if (mensagemRecebida.responsavel.id !== usuario.value.idFuncionario) {
                //     pedirPermissaoNotificacao(() => exibirNotificacao(mensagemRecebida));
                // }
            });

            socket.value.on(EVENTOS_SOCKET.MENSAGENS_SALA, (dados) => {
                // Recebimento de todas as mensagens enviadas no chat (usado para conexões posteriores e reconexões)
                dados = JSON.parse(dados);
                dados.mensagens.forEach(m => {
                    if (!(m.dataHora instanceof Date)) {
                        m.dataHora = new Date(m.dataHora);
                    }
                });

                // if (temPermissaoRBAC(usuario, PERMISSOES.ATENDIMENTO)) {
                //     dados.mensagens = dados.mensagens.filter(m => !m.atendido);
                // }

                console.log('gggggggggggggggggg')
                console.log(dados)

                const dataAtendimento = moment(dados.dataHoraInicio).format('HH:mm - DD/MM/YYYY');
                dados.mensagens.unshift({
                    notificacao: true,
                    mensagem: `Evento iniciado às ${dataAtendimento}`,
                    atendimento: true
                });
                mensagens.value = [...dados.mensagens];

                setTimeout(() => {
                    divRef.current && divRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start'
                    })
                }, 100)
            });

            socket.value.on(EVENTOS_SOCKET.DADOS_SALA, (dados) => {
                dados = JSON.parse(dados);

                batch(() => {
                    quantidadeMembrosSala.value = parseInt(dados.quantidadeMembroSala);
                    idSalaAtendimento.value = dados.idSala;
                    idEvento.value = dados.idEvento;
                    emChamadaVideo.value = dados.videoAtivo;
                    // emAtendimento.value = true;
                    // atendimentoEncerrado.value = false;
                });
            });

            socket.value.on(EVENTOS_SOCKET.ATENDIMENTO_ENCERRADO, (dados) => {
                dados = JSON.parse(dados)
                atendimentoEncerrado.value = true;
                emChamadaVideo.value = false;
                const dataAtendimento = moment().format('HH:mm - DD/MM/YYYY');
                batch(() => {
                    atendimentoEncerrado.value = true;
                    const novasMensagens = [];
                    novasMensagens.push({
                        notificacao: true,
                        mensagem: `Evento finalizado às ${dataAtendimento}`,
                        atendimento: true
                    });

                    mensagens.value = [...mensagens.value, ...novasMensagens];
                    setTimeout(() => {
                        divRef.current && divRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'start'
                        })
                    }, 100);
                });
            });

            // if (temPermissaoRBAC(usuario, CONSTANTES.PERMISSOES.ATENDIMENTO)) {
            //     socket.value.on(EVENTOS_SOCKET.ATENDIDO_DESCONECTADO, (dados) => {
            //         mensagens.value.push({
            //             notificacao: true,
            //             mensagem: `O usuário atendido se desconectou`,
            //             atendimento: false
            //         });

            //         batch(() => {
            //             quantidadeMembrosSala.value = quantidadeMembrosSala.value--;
            //             mensagens.value = [...mensagens.value];
            //         })

            //         setTimeout(() => {
            //             divRef.current && divRef.current.scrollIntoView({
            //                 behavior: 'smooth',
            //                 block: 'end',
            //                 inline: 'start'
            //             })
            //         }, 100)
            //     });

            //     socket.value.on(EVENTOS_SOCKET.ATENDENTE_DESCONECTADO, (dados) => {
            //         dados = JSON.parse(dados);
            //         quantidadeMembrosSala.value = parseInt(dados.quantidadeMembroSala);
            //     });
            // } else {
            //     socket.value.on(EVENTOS_SOCKET.ATENDENTE_DESCONECTADO, (dados) => {
            //         dados = JSON.parse(dados);
            //         quantidadeMembrosSala.value = parseInt(dados.quantidadeMembroSala);
            //         if (quantidadeMembrosSala.value === 1) {
            //             mensagens.value.push({
            //                 notificacao: true,
            //                 mensagem: `O atendente se desconectou. Aguarde o retorno de um de nossos atendentes para continuar com o envio de dúvidas.`,
            //                 atendimento: false
            //             });
            //         }
            //     });
            // }
        }
    })

    return (
        <SttBox
            sx={{
                height: "99%",
                width: "99.7%",
                position: "absolute",
                top: 0,
                display: "flex",
                flexDirection: "column",
                paddingTop: '8px',
                paddingRight: '8px',
                overflow: "auto",
                backgroundColor: '#EFEFEF'
            }}
        >
            <div ref={divRef}>
                <ExibicaoMensagemContainer mensagens={mensagens.value} />
            </div>
        </SttBox>
    );
};

export default MensagensContainer;