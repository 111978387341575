import React, { useContext, useEffect } from "react";
import { SttBox, SttNotification, SttTranslateHook } from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core";
import usuario from "../../signals/usuario";
import socket, { erroConexacaoSocket } from "../../signals/socket";
import { temPermissaoRBAC } from '../../security/rbac';
import { EVENTOS_SOCKET, PERMISSOES, SITUACAO_AGENDAMENTO } from '../../common/Constants';
import { useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { connect } from 'socket.io-client';
// import PainelDireita from "./painel-direita";
import { agendamentoEvento, atendimentoEncerrado, emAtendimento, usuarioEmAtendimento } from "../../signals/eventos";
import { getAuthorizationHeader, getHeaders } from "../../request";
import axios from "axios";
import Painel from "./painel";

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: 'calc(100vh - 104px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 124px)',
        },
        flexDirection: 'column',
        display: 'flex',
        overflow: 'hidden'
    },
    wrapperAtendimento: {
        height: 'calc(100vh - 104px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 124px)',
        },
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        overflow: 'hidden'
    },
    notificationBox: {
        width: '100%',
        height: '100px',
        minHeight: '100px',
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            height: '220px',
            minHeight: '220px',
        },
    },
    notificacao: {
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 'larger'
    },
}));

const HomeEvento = ({ id }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_conecta}/agendamento/${id}`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                console.log(data)
                if ([SITUACAO_AGENDAMENTO.SITUACAO.CANCELADO, SITUACAO_AGENDAMENTO.SITUACAO.CONVITES_ENVIADOS, SITUACAO_AGENDAMENTO.SITUACAO.REAGENDADO].includes(data.agendamento.situacao)) {
                    // TODO: TRATAR EVENTO NÃO AUTORIZADO A ACONTECER
                    return;
                }
                console.log(data.agendamento)
                agendamentoEvento.value = data.agendamento;

                iniciarConexaoWebsocket();
            })
            .catch(err => {
                console.log(err)
            });

        return () => {
            if (socket.value) {
                socket.value.disconnect();
                socket.value = null;
            }
        }
        // TODO: VERIFICAR SE O AGENDAMENTO EXISTE E TRATAR AS POSSÍVEIS SITUAÇÕES.
    }, [id]);

    const iniciarConexaoWebsocket = () => {
        const options = {
            query: { idAgendamento: id },
            forceNew: false,
            extraHeaders: {
                ...getAuthorizationHeader()
            },
            // transports: ['websocket', 'polling'],
            reconnectionDelay: 5000, // defaults to 1000
            reconnectionDelayMax: 8000 // defaults to 5000
        };
        if (global.gConfig.url_base_socket_path !== '') {
            options.path = global.gConfig.url_base_socket_path;
        }
        socket.value = connect(global.gConfig.url_base_socket, options);

        const dadosUsuario = {
            nome: usuario.value.nome,
            // atendente: temPermissaoRBAC(usuario, PERMISSOES.INTERNO),
            id: usuario.value.idFuncionario,
            cpf: usuario.value.cpf,
            uf: usuario.value.estado,
            municipio: usuario.value.cidade,
        };

        // TODO: TRATAR EVENTO FINALIZADO (ABRIR APENAS EM MODO LEITURA)

        socket.value.on("connect", () => {
            if (erroConexacaoSocket.value) {
                window.location.reload();
            }
            
            atendimentoEncerrado.value = agendamentoEvento.value.situacao === SITUACAO_AGENDAMENTO.SITUACAO.FINALIZADO;
            socket.value.emit(EVENTOS_SOCKET.DADOS_USUARIO, JSON.stringify(dadosUsuario));
        });

        function handleErrors(err) {
            erroConexacaoSocket.value = true;

            const box = document.getElementById('box-atendimento');

            const buttons = box.getElementsByTagName('button');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].disabled = 'disabled';
            }

            const inputs = box.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].disabled = 'disabled';
            }
        }

        socket.value.on('connect_error', err => handleErrors(err));
        socket.value.on('connect_failed', err => handleErrors(err));
        socket.value.on('disconnect', err => handleErrors(err));
    };

    return (
        <SttBox className={classes.wrapper}>
            {
                erroConexacaoSocket.value &&
                <SttBox className={classes.notificationBox}>
                    <SttNotification severity='error' className={classes.notificacao}>
                        <div dangerouslySetInnerHTML={{ __html: strings.avisoSemConexao }} />
                    </SttNotification>
                </SttBox>
            }
            <SttBox className={classes.wrapperAtendimento} id="box-atendimento">
                {
                    agendamentoEvento.value &&
                    <Painel />
                }
            </SttBox>
        </SttBox>
    );
}

export default HomeEvento;