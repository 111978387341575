import React, { useContext } from "react";
import {
    SttBox,
    SttHeading,
    SttHidden,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignals } from "@preact/signals-react/runtime";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import {
    atendimentoEncerrado,
    emChamadaVideo,
    idSalaAtendimento,
} from "../../signals/eventos";
import ChatFormContainer from './chat-form';
import {
    CameraDeVideoDesligadaIcon,
    CameraDeVideoLigadaIcon,
    DesligarIcon
} from '@stt-componentes/icons';
// import ModalRegistroAtendimento from "./registro";
import { agendamentoEvento } from "../../signals/eventos";
import MensagensContainer from "./mensagens";
import { EVENTOS_SOCKET } from "../../common/Constants";
import socket from "../../signals/socket";

const useStyles = makeStyles(theme => ({
    heading: {
        marginLeft: theme.spacing(2)
    },
    headerBox: {
        height: '63px',
        padding: "0px 15px",
        borderBottom: '1px solid #D9D9D9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center"
    },
    iconButton: {
        marginLeft: theme.spacing(1.5),
    },
}));

const Janela = ({ eventoAberto }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    // const inserirRegistro = useSignal(false);

    return (
        <>
            {/* Container com o header do painel */}
            <SttBox className={classes.headerBox}>
                {/* Container com o título/subtítulo do cabeçalho */}
                <SttBox
                    display="flex"
                    flexDirection="column"
                    pl="4px"
                    alignItems="flex-start"
                >
                    <SttBox display="flex" justifyContent="space-between" width="100%">
                        <SttHeading variant="h2" color="primary">{agendamentoEvento.value.assunto}</SttHeading>
                    </SttBox>
                </SttBox>
                {/* Container com os botões de ação */}
                {
                    !agendamentoEvento.value.finalizado &&
                    <SttBox display="flex" alignItems="flex-end">
                        <SttHidden smUp>
                            {
                                emChamadaVideo.value && eventoAberto?.value &&
                                <Tooltip title={strings.abrirJanelaVideo}>
                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                        onClick={() => {
                                            eventoAberto.value = false;
                                        }}>
                                        <CameraDeVideoLigadaIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </SttHidden>
                        {
                            // temPermissaoRBAC(usuario, PERMISSOES.ATENDIMENTO) &&
                            // usuario.value.cbo.some(cbo => global.gConfig.codigo_cbo_registro.includes(parseInt(cbo.codigo_cbo_familia))) &&
                            // <Tooltip title={strings.registroAtendimento}>
                            //     <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                            //         onClick={() => {
                            //             inserirRegistro.value = true;
                            //         }}>
                            //         <RegistroIcon />
                            //     </IconButton>
                            // </Tooltip>
                        }
                        {
                            !atendimentoEncerrado.value &&
                            <>
                                {
                                    //  temPermissaoRBAC(usuario, PERMISSOES.ATENDIMENTO) &&
                                    !emChamadaVideo.value ?
                                        <Tooltip title={strings.chamadaVideo}>
                                            <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                                onClick={() => {
                                                    socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_ATENDIMENTO_VIDEO, JSON.stringify({
                                                        idSala: idSalaAtendimento.value,
                                                        videoAtivo: true
                                                    }));
                                                }}>
                                                <CameraDeVideoLigadaIcon />
                                            </IconButton>
                                        </Tooltip> :
                                        <Tooltip title={strings.fecharChamadaVideo}>
                                            <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                                onClick={() => {
                                                    socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_ATENDIMENTO_VIDEO, JSON.stringify({
                                                        idSala: idSalaAtendimento.value,
                                                        videoAtivo: false
                                                    }));
                                                }}>
                                                <CameraDeVideoDesligadaIcon />
                                            </IconButton>
                                        </Tooltip>
                                }
                                {/* temPermissaoRBAC(usuario, PERMISSOES.ATENDIMENTO) && */}
                                <Tooltip title={strings.finalizarAtendimento}>
                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                        onClick={() => {
                                            // socket.value.emit(EVENTOS_SOCKET.FINALIZAR_ATENDIMENTO, {
                                            //     idSala: idSalaAtendimento.value,
                                            //     idFuncionario: usuario.value.idFuncionario
                                            // });
                                        }}>
                                        <DesligarIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </SttBox>
                }
            </SttBox>
            {/* Container de mensagens enviadas */}
            <SttBox flex={1} minHeight={0} position="relative">
                <SttBox
                    height="100%"
                    width="100%"
                    alt="background"
                />
                <MensagensContainer />
            </SttBox>
            {
                !atendimentoEncerrado.value &&
                <ChatFormContainer />
            }
            {/* {
                inserirRegistro.value &&
                <ModalRegistroAtendimento inserirRegistro={inserirRegistro} />
            } */}
        </>
    );
}

export default Janela;